import React, { Fragment, useState } from 'react';
import { navigate } from 'gatsby-link';
import { RichText } from 'prismic-reactjs';

// material ui plugin for creating date-time-picker
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
// date util library
import MomentUtils from '@date-io/moment';
// @material-ui/core components
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// core components
import Button from '../CustomButtons/Button';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  section: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  close: {
    padding: theme.spacing(0.5),
  },
  stepTitle: {
    fontFamily: 'Quattrocento',
    fontWeight: 'bold',
  },
}));

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const ContactForm = ({ labels }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [values, setValues] = useState({
    company: '',
    firstname: '',
    lastname: '',
    email: '',
    skype: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    destination: '',
    people: 1,
    budget: '',
    startDate: null,
    endDate: null,
    message: '',
    datesSpecified: false,
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const form = event.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...values,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => {
        setOpen(true);
        setErrorMessage(error);
      });
  };

  const handleCheck = name => event => {
    setValues({ ...values, [name]: event.target.checked });
  };

  const handleStartDateChange = date => {
    setValues({ ...values, startDate: date });
  };

  const handleEndDateChange = date => {
    setValues({ ...values, endDate: date });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Container component="main" maxWidth="sm">
          <Paper className={classes.paper}>
            <form
              name="contact"
              method="POST"
              action="/success"
              netlify-honeypot="bot-field"
              data-netlify="true"
              onSubmit={handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label htmlFor="bot-field">
                  Don’t fill this out:
                  <input name="bot-field" onChange={handleChange('bot-field')} />
                </label>
              </p>
              <Grid container spacing={3} className={classes.section}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom className={classes.stepTitle}>
                    {RichText.asText(labels.who_section_label.raw)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="firstname"
                    name="firstname"
                    label={RichText.asText(labels.first_name_label.raw)}
                    fullWidth
                    autoComplete="fname"
                    value={values.firstname}
                    onChange={handleChange('firstname')}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="lastname"
                    name="lastname"
                    label={RichText.asText(labels.last_name_label.raw)}
                    fullWidth
                    autoComplete="lname"
                    value={values.lastname}
                    onChange={handleChange('lastname')}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="company"
                    name="company"
                    label={RichText.asText(labels.company_label.raw)}
                    fullWidth
                    autoComplete="company"
                    value={values.company}
                    onChange={handleChange('company')}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label={RichText.asText(labels.email_label.raw)}
                    type="email"
                    fullWidth
                    autoComplete="email"
                    value={values.email}
                    onChange={handleChange('email')}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="skype"
                    name="skype"
                    label={RichText.asText(labels.skype_label.raw)}
                    fullWidth
                    autoComplete="skype"
                    value={values.skype}
                    onChange={handleChange('skype')}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Divider variant="middle" />
              <Grid container spacing={3} className={classes.section}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom className={classes.stepTitle}>
                    {RichText.asText(labels.where_section_label.raw)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="address1"
                    name="address1"
                    label={RichText.asText(labels.address_label.raw)}
                    fullWidth
                    autoComplete="billing address-line1"
                    value={values.address1}
                    onChange={handleChange('address1')}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="address2"
                    name="address2"
                    label={RichText.asText(labels.additional_information_label.raw)}
                    fullWidth
                    autoComplete="billing address-line2"
                    value={values.address2}
                    onChange={handleChange('address2')}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="city"
                    name="city"
                    label={RichText.asText(labels.city_label.raw)}
                    fullWidth
                    autoComplete="billing address-level2"
                    value={values.city}
                    onChange={handleChange('city')}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="state"
                    name="state"
                    label={RichText.asText(labels.state_label.raw)}
                    fullWidth
                    value={values.state}
                    onChange={handleChange('state')}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="zip"
                    name="zip"
                    label={RichText.asText(labels.zip_code_label.raw)}
                    fullWidth
                    autoComplete="billing postal-code"
                    value={values.zip}
                    onChange={handleChange('zip')}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="country"
                    name="country"
                    label={RichText.asText(labels.country_label.raw)}
                    fullWidth
                    autoComplete="billing country"
                    value={values.country}
                    onChange={handleChange('country')}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Divider variant="middle" />
              <Grid container spacing={3} className={classes.section}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom className={classes.stepTitle}>
                    {RichText.asText(labels.request_section_label.raw)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="destination"
                    name="destination"
                    label={RichText.asText(labels.destination_label.raw)}
                    fullWidth
                    value={values.destination}
                    onChange={handleChange('destination')}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="people"
                    name="people"
                    label={RichText.asText(labels.people_label.raw)}
                    fullWidth
                    type="number"
                    min="1"
                    value={values.people}
                    onChange={handleChange('people')}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="budget"
                    name="budget"
                    label={RichText.asText(labels.budget_label.raw)}
                    fullWidth
                    type="number"
                    min="1"
                    value={values.budget}
                    onChange={handleChange('budget')}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    value="top"
                    control={
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>No</Grid>
                        <Grid item>
                          <Switch
                            checked={values.datesSpecified}
                            onChange={handleCheck('datesSpecified')}
                            value={values.datesSpecified}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    }
                    label={RichText.asText(labels.select_date_label.raw)}
                    labelPlacement="top"
                  />
                </Grid>
                {values.datesSpecified ? (
                  <Fragment>
                    <Grid item xs={12} sm={6}>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        format="MMM Do YYYY"
                        name="startDate"
                        value={values.startDate}
                        onChange={date => handleStartDateChange(date)}
                        minDate={new Date()}
                        label={RichText.asText(labels.start_date_label.raw)}
                        inputVariant="outlined"
                        InputAdornmentProps={{ position: 'start' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        format="MMM Do YYYY"
                        value={values.endDate}
                        onChange={date => handleEndDateChange(date)}
                        minDate={values.startDate}
                        label={RichText.asText(labels.end_date_label.raw)}
                        inputVariant="outlined"
                        InputAdornmentProps={{ position: 'start' }}
                      />
                    </Grid>
                  </Fragment>
                ) : null}
                <Grid item xs={12}>
                  <TextField
                    required
                    id="message"
                    name="message"
                    label={RichText.asText(labels.message_label.raw)}
                    fullWidth
                    multiline
                    rows="5"
                    rowsMax="10"
                    value={values.message}
                    onChange={handleChange('message')}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <div className={classes.buttons}>
                <Button color="success" className={classes.button} type="submit">
                  {RichText.asText(labels.send_button_label.raw)}
                </Button>
              </div>
            </form>
          </Paper>
        </Container>
      </MuiPickersUtilsProvider>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        // eslint-disable-next-line react/jsx-one-expression-per-line
        message={<span id="message-id">${errorMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Fragment>
  );
};

export default ContactForm;
