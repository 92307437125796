import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Layout from 'components/Layout';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Parallax from 'components/Parallax/Parallax';
import SEO from 'components/SEO';
import ContactForm from 'components/ContactForm/ContactForm';

import { EmailNewsletter, Phone } from 'mdi-material-ui';

import backgroundImage from 'assets/img/contact-bg.jpg';
import contactStyle from 'assets/jss/material-kit-react/views/contactPage';

const ContactPage = ({ classes, data }) => {
  if (!data) return null;

  const content = data.prismicContactPage.data;
  const { lang } = data.prismicContactPage;
  const alternateLanguages = data.prismicContactPage.alternate_languages;

  const activeDocMeta = { lang, alternateLanguages };

  const { tours } = data.prismicHomepage.data;

  const menu = data.prismicMenu.data;
  if (!menu) return null;

  return (
    <Layout activeDocMeta={activeDocMeta} menu={menu} tours={tours}>
      <SEO title={`${RichText.asText(content.title.raw)} | Servitours`} />
      <Parallax filter image={backgroundImage} className={classes.parallax}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>{RichText.asText(content.title.raw)}</h1>
                <br />
                <h3 className={classes.subtitle}>
                  {RichText.asText(content.subtitle.raw)}
                  <br />
                  <small className={classes.small}>
                    <RichText render={content.paragraph.raw} />
                  </small>
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            <GridContainer justifyContent="space-around">
              <GridItem sm={4}>
                <Card>
                  <CardBody>
                    <Phone htmlColor="#9c27b0" fontSize="large" />
                    {lang === 'en-us' ? (
                      <h3 className={classes.info}>By phone</h3>
                    ) : (
                      <h3 className={classes.info}>Par téléphone</h3>
                    )}
                    <h4>{RichText.asText(content.phone.raw)}</h4>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem sm={4}>
                <Card>
                  <CardBody>
                    <EmailNewsletter htmlColor="#9c27b0" fontSize="large" />
                    {lang === 'en-us' ? (
                      <h3 className={classes.info}>By email</h3>
                    ) : (
                      <h3 className={classes.info}>Par mail</h3>
                    )}
                    <h4>
                      <a
                        title="Send us an email"
                        href={`mailto:${RichText.asText(content.email.raw)}`}
                      >
                        {RichText.asText(content.email.raw)}
                      </a>
                    </h4>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ContactForm lang={lang} labels={content} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

// Query for the Contact page content in Prismic
export const query = graphql`
  query ContactPageQuery($lang: String = "fr-fr") {
    prismicContactPage(lang: { eq: $lang }) {
      alternate_languages {
        lang
        type
        uid
      }
      data {
        additional_information_label {
          raw
        }
        address_label {
          raw
        }
        budget_label {
          raw
        }
        city_label {
          raw
        }
        company_label {
          raw
        }
        country_label {
          raw
        }
        destination_label {
          raw
        }
        email {
          raw
        }
        email_label {
          raw
        }
        end_date_label {
          raw
        }
        first_name_label {
          raw
        }
        last_name_label {
          raw
        }
        message_label {
          raw
        }
        paragraph {
          html
          raw
        }
        people_label {
          raw
        }
        phone {
          raw
        }
        request_section_label {
          raw
        }
        select_date_label {
          raw
        }
        send_button_label {
          raw
        }
        skype_label {
          raw
        }
        start_date_label {
          raw
        }
        state_label {
          raw
        }
        subtitle {
          raw
        }
        title {
          raw
        }
        where_section_label {
          raw
        }
        who_section_label {
          raw
        }
        zip_code_label {
          raw
        }
      }
      lang
      type
    }
    prismicHomepage(lang: { eq: $lang }) {
      data {
        tours {
          destination {
            document {
              ... on PrismicDestination {
                id
                lang
                type
                uid
                url
                data {
                  name {
                    raw
                  }
                }
              }
            }
          }
          message {
            raw
          }
        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      data {
        links {
          label {
            raw
          }
          link {
            lang
            type
            url
          }
        }
      }
    }
  }
`;

export default withStyles(contactStyle)(ContactPage);
